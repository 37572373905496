import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import 'firebase/firestore'
import firebase from 'gatsby-plugin-firebase'
import './commentForm.css'
 
class CommentForm extends React.Component {
    constructor(props) {
        super(props)
        this.name = props.name
        this.db = firebase.firestore()
        this.state = {
            comments: []
        }
    }
    componentDidMount() {
        this.db.collection('blogs').doc(this.name).collection('comments').get().then(({docs}) => {
            console.log(docs)
            docs.forEach(doc => {
                this.setState({
                    comments: [...this.state.comments, doc.data()]
                })
                console.log(doc.data(), this.state.comments)
            })
        })
    }
    render() {
        return (
            <div className='comment-area'>
                <h1>Comments</h1>
                {this.state.comments.map((item, key) => 
                <div className='comment-section' key={key}>
                    <div className='comment-profile'>
                        <div className='comment-profile-name'>{item.name[0]}</div>
                    </div>
                    <div className='comment-text'>
                        <div><span>{item.name}: {item.comment}</span></div>
                        <div className='comment-profile-date'>{new Date(item.datetime.seconds * 1000).toLocaleString()}</div>
                    </div>
                </div>
                )}
                <form className='comment-post-area'>
                    <TextField
                        id="standard-full-width"
                        label="Leave a message :)"
                        placeholder="Tell me what you are thinking of..."
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Button type="submit" color="primary">
                        <span>Post it!</span>
                    </Button>
                </form>
            </div>
        )
    }
}

export default CommentForm