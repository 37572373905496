import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import CommentForm from '../components/commentForm'
import SEO from '../components/seo'
import 'prismjs/themes/prism-tomorrow.css'
import './blog-post.css'

export default ({ data, pageContext }) => {
    const post = data.markdownRemark
    const background = post.frontmatter.background
    return (
        <Layout>
            <div className="blog-post-header" style={{
                background: background ? background : ``
            }}>
                <header className="container">
                    <h2>{post.frontmatter.title}</h2>
                </header>
            </div>
            <div className="container">
                <div className="blog-post-detail">
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    {/* <CommentForm name={pageContext.slug.split('/').join('')} /> */}
                </div>
            </div>
            <SEO title={post.frontmatter.title} />
        </Layout>
    )
}

export const query = graphql`
query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
        html
        frontmatter {
            title
            background
        }
    }
}
`